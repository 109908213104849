(function ($) {
  $.fn.selectpicker.defaults = {
    noneSelectedText: 'ไม่ได้เลือกอะไรเลย',
    noneResultsText: 'ไม่มีผลลัพธ์ที่ตรงกัน {0}',
    countSelectedText: '{0} รายการที่เลือก',
    maxOptionsText: ['เกินจำนวนที่กำหนด (สูงสุด {n} รายการ)', 'เกินจำนวนที่กำหนด (สูงสุด {n} กลุ่ม)'],
    selectAllText: 'เลือกทั้งหมด',
    deselectAllText: 'ไม่เลือกทั้งหมด',
    multipleSeparator: ', '
  };
})(jQuery);
